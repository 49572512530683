<template>
  <div>
    <span v-if="this.$route.name === 'team-europe'"></span>
    <span v-else>
      <Sponsors /> 
    </span>
    <div class="footer">
      <b-container>
        
        <template v-if="isMobile(true)">
          <b-row>
            <b-col>
              <b-nav-item class="footMenu" to="/partners-suppliers"
                >Partners & Suppliers</b-nav-item
              >
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'order-of-merit',
                  query: { id: season, oom: 'PT' },
                }"
                >Rankings</b-nav-item
              >
              <b-nav-item class="footMenu" :href="'https://www.flickr.com/photos/ladieseuropeantour/'" target="_blank">Photos</b-nav-item>
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'videos',
                  query: { id: '' },
                }"
                >Videos</b-nav-item
              >
              <b-nav-item class="footMenu" :to="'/the-let-golf-podcast'">The LET Golf Podcast</b-nav-item>
              <b-nav-item class="footMenu" :to="'/news'">News</b-nav-item>
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'players',
                }"
                >Players</b-nav-item
              >
              <b-nav-item class="footMenu" :to="'/tournaments'"
                >Tournaments</b-nav-item
              >
              <!-- <b-nav-item
                class="footMenu"
                href="https://ocs-let.com/let-members/"
                target="_blank"
                >Player Portal</b-nav-item
              > -->
            </b-col>
            <b-col>
              <b-nav-item
                class="footMenu"
                href="https://letaccess.com/"
                target="_blank"
                >LET Access Series</b-nav-item
              >
              <b-nav-item class="footMenu" to='/board-of-directors'>Board of Directors</b-nav-item>
              <b-nav-item class="footMenu" to='/about-let'>About LET</b-nav-item>
              <b-nav-item class="footMenu" href='https://apps.ladieseuropeantour.com/apps/' target="_blank">Media Centre</b-nav-item>
              <!-- <b-nav-item
                class="footMenu"
                href="https://lpga.app.box.com/s/xgtgkm5ia9jwuo4nyf3807lfo3g6rg39"
                target="_blank"
                >All Time Records</b-nav-item
              > -->
              <b-nav-item class="footMenu" to='/contact-let'>Contact LET</b-nav-item>
              <b-nav-item class="footMenu" to='/privacy-policy'>Privacy Policy</b-nav-item>
              <b-nav-item class="footMenu" to='/terms-and-conditions'>Terms And Conditions</b-nav-item>
              <b-nav-item class="footMenu" to='/competition-tcs'>Competition T&C’s</b-nav-item>
              <b-nav-item class="footMenu" to='/careers'>Careers</b-nav-item>
            </b-col>
          </b-row>
          <b-row class="socialRowMob">
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" href="https://www.tiktok.com/@letgolf?lang=en" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" /> 
              </b-nav-item>
            </b-col>
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'facebook']" /> 
              </b-nav-item>
            </b-col>
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">
                <img class='newTwitterMob' src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png">
              </b-nav-item>
            </b-col>
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank">
                <font-awesome-icon
                  class="icon"
                  :icon="['fab', 'instagram']"
                />
              </b-nav-item>
            </b-col>
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" :href="'https://www.youtube.com/user/'+config.VUE_APP_YOUTUBE_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
              </b-nav-item>
            </b-col>
            <b-col class="socialWidthMob">
              <b-nav-item class="menuBar" :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
              </b-nav-item>
            </b-col>
          </b-row>
          <b-row class="reg">
            <b-col cols="9">
              <p class="footerText">
                Ladies European Golf Venture Limited, a private limited company
                registered in England
              </p>
              <p class="footerText">
                Registration number: 12352756 – VAT registration GB 340 4610 40
              </p>
              <p class="footerText">
                Registered Office: Buckinghamshire Golf Club, Denham Court Dr.
              </p>
              <p class="footerText">Buckinghamshire, United Kingdom UB9 5PG</p>
            </b-col>
            <b-col cols="3">
              <b-link href="https://ocs-sport.com/#/" target="_blank">
                <b-img
                  class="ocsMob"
                  :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/12/OCS_Sport_Logo_2017_WO.png'"
                ></b-img>
              </b-link>
            </b-col>
          </b-row>


        </template>
        <template v-else>
          <b-row>
            <b-col>
              <b-nav-item class="footMenu" to="/partners-suppliers"
                >Partners & Suppliers</b-nav-item
              >
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'order-of-merit',
                  query: { id: season, oom: 'PT' },
                }"
                >Rankings</b-nav-item
              >
              <b-nav-item class="footMenu" :href="'https://www.flickr.com/photos/ladieseuropeantour/'" target="_blank">Photos</b-nav-item>
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'videos',
                  query: { id: '' },
                }"
                >Videos</b-nav-item
              >
              <b-nav-item class="footMenu" :to="'/the-let-golf-podcast'">The LET Golf Podcast</b-nav-item>
              <b-nav-item class="footMenu" :to="'/news'">News</b-nav-item>
              <b-nav-item
                class="footMenu"
                :to="{
                  name: 'players',
                }"
                >Players</b-nav-item
              >
              <b-nav-item class="footMenu" :to="'/tournaments'"
                >Tournaments</b-nav-item
              >
              <!-- <b-nav-item
                class="footMenu"
                href="https://ocs-let.com/let-members/"
                target="_blank"
                >Player Portal</b-nav-item
              > -->
            </b-col>
            <b-col>
              <b-nav-item
                class="footMenu"
                href="https://letaccess.com/"
                target="_blank"
                >LET Access Series</b-nav-item
              >
              <b-nav-item class="footMenu" to='/board-of-directors'>Board of Directors</b-nav-item>
              <b-nav-item class="footMenu" to='/about-let'>About LET</b-nav-item>
              <b-nav-item class="footMenu" href='https://apps.ladieseuropeantour.com/apps/' target="_blank">Media Centre</b-nav-item>
              <!-- <b-nav-item
                class="footMenu"
                href="https://lpga.app.box.com/s/xgtgkm5ia9jwuo4nyf3807lfo3g6rg39"
                target="_blank"
                >All Time Records</b-nav-item
              > -->
            </b-col>
            <b-col>
              <b-nav-item class="footMenu" to='/contact-let'>Contact LET</b-nav-item>
              <b-nav-item class="footMenu" to='/privacy-policy'>Privacy Policy</b-nav-item>
              <b-nav-item class="footMenu" to='/terms-and-conditions'>Terms And Conditions</b-nav-item>
              <b-nav-item class="footMenu" to='/competition-tcs'>Competition T&C’s</b-nav-item>
              <b-nav-item class="footMenu" to='/careers'>Careers</b-nav-item>
            </b-col>
            <b-col>
              <b-row>
                <b-col lg="2">
                  <b-nav-item class="menuBar" href="https://www.tiktok.com/@letgolf?lang=en" target="_blank">
                    <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" /> 
                  </b-nav-item>
                </b-col>
                <b-col lg="2">
                  <b-nav-item class="menuBar" :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank">
                    <font-awesome-icon class="icon" :icon="['fab', 'facebook']" /> 
                  </b-nav-item>
                </b-col>
                <b-col lg="2">
                  <b-nav-item class="menuBar" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">
                    <img class='newTwitter' src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png">
                  </b-nav-item>
                </b-col>
                <b-col lg="2">
                  <b-nav-item class="menuBar" :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'instagram']"
                    />
                  </b-nav-item>
                </b-col>
                <b-col lg="2">
                  <b-nav-item class="menuBar" :href="'https://www.youtube.com/user/'+config.VUE_APP_YOUTUBE_NAME" target="_blank">
                    <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="2">
                  <b-nav-item class="menuBar" :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME" target="_blank">
                    <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
                  </b-nav-item>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="reg">
            <b-col>
              <p class="footerText">
                Ladies European Golf Venture Limited, a private limited company
                registered in England
              </p>
              <p class="footerText">
                Registration number: 12352756 – VAT registration GB 340 4610 40
              </p>
              <p class="footerText">
                Registered Office: Buckinghamshire Golf Club, Denham Court Dr.
              </p>
              <p class="footerText">Buckinghamshire, United Kingdom UB9 5PG</p>
            </b-col>
            <b-col>
              <b-link href="https://ocs-sport.com/#/" target="_blank">
                <b-img
                  class="ocs"
                  :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/12/OCS_Sport_Logo_2017_WO.png'"
                ></b-img>
              </b-link>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </div>
  </div>
</template>

<script>
import Sponsors from "@/components/sponsors.vue";
export default {
  name: "Footer",
  props: ["season", 'config'],
  components: { Sponsors },
  data() {
    return {
      footerData: [],
    };
  },
  methods:{
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style scoped>
.ocsMob {
  width: 53px;
}
img.newTwitterMob {
  width: 12px;
}
.socialWidthMob.col > li.nav-item.menuBar > a {
  padding: 0px;
}
.socialWidthMob.col {
  width: 10px;
}
.row.socialRowMob {
  margin-top: 25px;
}
img.newTwitter {
  width: 16px;
}
::v-deep .title {
  position: absolute;
  left: 15px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.footer {
  background-color: #01477b;
  padding: 10px 0;
}
.footMenu {
  font-size: 9pt;
  line-height: 1.5;
}
.footMenu > a {
  color: white;
  padding: 0;
}
.menuBar > a {
  color: white;
}
li {
  list-style: none;
}
.footerText {
  text-transform: initial;
  font-size: 9pt;
  line-height: 0.3;
  color: white;
}
.reg {
  padding: 30px 0 0 0;
}
.ocs {
  float: right;
  width: 100px;
}
@media only screen and (max-width: 768px) {
  ::v-deep .footerText {
      text-transform: initial;
      font-size: 9pt;
      line-height: 1.3;
      color: white;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .title {
    padding-left: 20px;
  }
  ::v-deep .footerText {
    text-transform: initial;
    font-size: 9pt;
    line-height: 1.3;
    color: white;
  }
}

</style>
