<template>
    <div>
        <b-row class='featRow' v-for="(feature, index) in feat.slice(0,2)" :key='index'>
            <b-col>
                <b-nav-item :to="'/blog/' + feature.slug">
                    <b-img class='featImg' :src='feature.better_featured_image.source_url'></b-img>
                </b-nav-item>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="LETAS">
                <b-button class='access' href="https://letaccess.com/"
              target="_blank">LET Access Series</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'menuFeat',
    data(){
        return{
            feat:{}
        }
    },
    mounted() {
        axios
        .get(
            process.env.VUE_APP_WPAPI_URL+'wp/v2/posts?categories=6' )
        .then((response) => {
            this.feat = response.data;
        });
    },
}
</script>

<style scoped>
.LETAS {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
}
::v-deep li {
  list-style-type: none;
}
.featImg{
    width: 100%;
}
.nav-link{
    padding: .3rem;
}
.nav-link:hover{
    color: #fff;
    background: #42a7c6;
    text-decoration: none;
}
.featRow{
    /* padding: 10px 0; */
}
.access{
    width: 100%;
    background-color: #95c93d;
    border-radius: 0;
    border: 0;
}
.access:hover{
    background-color: #95c93d;
}
</style>